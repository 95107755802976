<template>
  <item-text :title="`${branch.customer.type.id === 1 ? 'Reģ. nr' : 'Pk'}`" :text="`${branch.reg_nr ? branch.reg_nr : 'Nav norādīts'}`" />
  <item-text title="Adrese" :text="branch.address" />
  <item-text title="Telefona nr." :text="`${branch.phone ? branch.phone :  'Nav norādīts'}`" />
  <item-text title="E-pasts" :text="`${branch.email ? branch.email :  'Nav norādīts'}`" />
  <item-text title="Menedžeris" :text="`${branch.manager ? branch.manager.name : 'Nav norādīts'}`" />
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowBranchDetails",
  components: {
    ItemText
  },
  props: {
    branch: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style>
</style>