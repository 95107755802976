<template>

  <form v-if="form && inputData" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Input name="name" placeholder="Filiāles nosaukums" v-model="form.name" :errors="errors.name" />
      </div>

      <div class="col-span-1 flex items-center">
        <Checkbox text="Reģ. nr. atšķiras" v-model="differentRegNr" />
      </div>

      <template v-if="differentRegNr">
        <div class="col-span-1">
          <Input name="reg_nr" placeholder="Reģistrācijas nr." v-model="form.reg_nr" :errors="errors.reg_nr" />
        </div>
      </template>

      <div class="col-span-1">
        <Input name="address" placeholder="Adrese" v-model="form.address" :errors="errors.address" />
      </div>

      <div class="col-span-1">
        <Input name="phone" placeholder="Telefona nr." v-model="form.phone" :errors="errors.phone" />
      </div>

      <div class="col-span-1">
        <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email" />
      </div>

      <div class="col-span-1">
        <Select v-model="form.manager" :errors="errors.manager" :items="inputData.managers" placeholder="Menedžeris" />
      </div>

    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="hideEditBranchForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>

  </form>

</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Components/Input";
import Checkbox from "@/components/Components/Checkbox";
import Select from "@/components/Components/Select";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditBranchDetails",
  data: () => ({
    form: null,
    differentRegNr: false,
  }),
  props: ["branch"],
  components: {
    Input,
    Checkbox,
    Select,
    Loading,
  },
  mounted() {
    this.$store.dispatch("getBranchInputData", this.branch.customer.id);
    this.branch.reg_nr
      ? (this.differentRegNr = true)
      : (this.differentRegNr = false);

      this.form = this.branch
  },
  computed: {
    ...mapGetters({
      inputData: "branchInputData",
      loading: "loading",
      errors: 'errors',
    }),
    setRegNr() {
      return this.differentRegNr ? this.regNr : "";
    },
    setInvoiceDeliveryEmail() {
      return this.branch.invoice_delivery_type
        ? this.branch.invoice_delivery_type.id === 2
          ? this.emailForInvoices
          : ""
        : "";
    },
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
        reg_nr: {
          rules: [ this.differentRegNr ? 'required' : null]
        },
        address: {
          rules: ['required']
        },
        email: {
          rules: ['email']
        },
      }
    },
  },
  methods: {
    hideEditBranchForm() {
      this.$store.dispatch("clearCustomerErrorMessages");
      this.$store.dispatch("getSingleBranch", this.branch.id);
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.$Progress.start();
    
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateBranch", {
          id: this.branch.id,
          name: this.branch.name,
          address: this.branch.address,
          phone: this.branch.phone,
          email: this.branch.email,
          reg_nr: this.setRegNr,
          managing_user_id: this.branch.manager ? this.branch.manager.id : null,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>