<template>
  <template v-if="branch.payment_details">
    <item-text title="Rēķina piegādes veids" :text="branch.payment_details.invoice_delivery_type.display_name" />
  </template>

  <template v-if="branch.customer.type.id === 1">
    <item-text title="Norēķinu regularitāte" :text="branch.payment_details.payment_frequency ? branch.payment_details.payment_frequency.display_name :  'Nav norādīts'" />
  </template>

  <template v-if="branch.payment_details.contacts.length > 0">
      <div class="my-2 w-full">
          <h3 class="px-6 my-2 w-full">
              Norēķinu kontakti:
          </h3>
          <div class="px-6 my-2" v-for="(contact, index) in branch.payment_details.contacts" :key="index">
            <ContactDetails :contact="contact" />
          </div>
      </div>
  </template>

</template>

<script>
import ItemText from "@/components/Components/ItemText"
import ContactDetails from "@/components/Customers/AdditionalFields/Contacts/ContactDetails"

export default {
  name: "ShowBranchPaymentDetails",
  components: {
    ItemText,
    ContactDetails,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style>
</style>