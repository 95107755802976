<template>
  <item-card additionalClass="divide-y divide-gray-200 dark:divide-gray-700">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Pamatdati
      </h3>
    </template>
    <template v-slot:buttons>

      <template v-if="this.$userCan('manage_customers') && !displayForm('EditBranchDetails')">
        <Button icon="pencil" @click="showForm('EditBranchDetails')" />

        <!-- Show details -->
        <Button :icon="showDetails ? 'chevron-up' : 'chevron-down'" @click="changeShowDetails" />

      </template>

    </template>
    <template v-slot:content>
      <template v-if="!displayForm('EditBranchDetails')">
        <ShowBranchDetails :branch="branch" />
      </template>

      <template v-if="displayForm('EditBranchDetails') && this.$userCan('manage_customers')">
        <EditBranchDetails :branch="branch" />
      </template>

    </template>

    <template v-slot:additional>
      <template v-if="showDetails">

        <div class="divide-y divide-gray-200 dark:divide-gray-700">
          <template v-if="branch.customer.payment_details">
            <template v-if="branch.customer.payment_details.payment_type">
              <template v-if="branch.customer.payment_details.payment_type.id === 2">
                <BranchPaymentDetails :branch="branch" />
              </template>
            </template>
          </template>

          <template v-if="branch.customer.type.id === 1">
            <BranchDetailContact :branch="branch"/>
          </template>

          <BranchDetailNotes :branch="branch"/>

          <BranchImages :branch="branch"/>
        </div>

      </template>
    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowBranchDetails from "@/components/Customer/Branches/Branch/BranchDetails/ShowBranchDetails"
import EditBranchDetails from "@/components/Customer/Branches/Branch/BranchDetails/EditBranchDetails"
import BranchPaymentDetails from "@/components/Customer/Branches/Branch/BranchDetails/PaymentDetails/BranchPaymentDetails"
import BranchDetailContact from "@/components/Customer/Branches/Branch/BranchDetails/BranchDetailContactPerson/BranchDetailContact"
import BranchDetailNotes from "@/components/Customer/Branches/Branch/BranchDetails/Notes/BranchDetailNotes"
import BranchImages from "@/components/Customer/Branches/Branch/BranchDetails/Images/BranchImages"

export default {
  name: "BranchDetails",
  components: {
    ItemCard,
    ShowBranchDetails,
    EditBranchDetails,
    BranchPaymentDetails,
    BranchDetailContact,
    BranchDetailNotes,
    BranchImages,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    showDetails: false
  }),
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    changeShowDetails() {
      this.showDetails = !this.showDetails
    }
  }
}
</script>

<style>

</style>