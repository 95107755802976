<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Filiāles galvenā kontaktpersona
      </h3>
    </template>
    <template v-slot:buttons>

      <template v-if="branch.customer.contacts.length > 1 && this.$userCan('manage_customers')">
        <Button icon="pencil" @click="showForm('EditBranchDetailContact')" />
      </template>

    </template>
    <template v-slot:content>

      <template v-if="!displayForm('EditBranchDetailContact')">
        <template v-if="branch.contact">
          <ShowBranchDetailContact :branch="branch"/>
        </template>

        <template v-else>
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Filiālei nav pievienota norēķinu info</p>

            <template v-if="branch.customer.contacts.length > 0 && this.$userCan('manage_customers')">
              <button @click="showForm('EditBranchDetailContact')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
                Pievienot
              </button>
            </template>
          </div>
        </template>
      </template>

      <template v-if="displayForm('EditBranchDetailContact') && this.$userCan('manage_customers')">
        <EditBranchDetailContact :branch="branch" />
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowBranchDetailContact from "@/components/Customer/Branches/Branch/BranchDetails/BranchDetailContactPerson/ShowBranchDetailContact"
import EditBranchDetailContact from "@/components/Customer/Branches/Branch/BranchDetails/BranchDetailContactPerson/EditBranchDetailContact"

export default {
  name: "BranchDetailContact",
  components: {
    ItemCard,
    ShowBranchDetailContact,
    EditBranchDetailContact,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>