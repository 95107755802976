<template>
  <router-link :to="`/contacts/${contact.id}`" class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full px-2 rounded-lg">
    <div class="py-4">
      <div class="sm:flex flex-wrap">
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <UserIcon class="w-6 h-6" />
              </span>
              {{contact.name}}
            </p>
          </template>
        </ItemText>
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <PhoneIcon class="w-6 h-6"/>
              </span>
              {{contact.phone ? contact.phone : 'Nav norādīts'}}
            </p>
          </template>
        </ItemText>
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <MailIcon class="w-6 h-6" />
              </span>
              {{contact.email ? contact.email : 'Nav norādīts'}}
            </p>
          </template>
        </ItemText>

        <div class="ml-auto">
          <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
            <button class="text-gray-400 flex items-center">
              Skatīt kontaktu
              <ChevronRightIcon class="w-6 h-6"/>
            </button>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import {
  UserIcon,
  PhoneIcon,
  MailIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";

export default {
  name: "ContactDetails",
  components: {
    ItemText,
    UserIcon,
    PhoneIcon,
    MailIcon,
    ChevronRightIcon,
  },
  props: ['contact'],
  methods: {
    viewContact(contactId){
      this.$router.push({ path: '/contacts/' + contactId })
    },
  }
}
</script>

<style>

</style>