<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Bildes
      </h3>
    </template>
    <template v-slot:buttons>

      <template v-if="branch.images.length > 0 && !displayForm('UploadImages')">
        <Button icon="plus" @click="showForm('UploadImages')" />
      </template>

    </template>
    <template v-slot:content>
      <template v-if="!displayForm('UploadImages')">

        <template v-if="branch.images.length > 0">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              <template v-for="(image, index) in branch.images" :key="index">
                <div class="col-span-1 flex items-center justify-center">
                  <ShowImage :image="image" :branch="branch"/>
                </div>
              </template>
          </div>
        </template>

        <template v-else>
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Filiālei nav pievienotas bildes</p>
            <button @click="showForm('UploadImages')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
              Pievienot
            </button>
          </div>
        </template>

      </template>

      <template v-else>
        <AddBranchImages :branch="branch"/>
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowImage from "@/components/Customer/Branches/Branch/BranchDetails/Images/ShowImage"
import AddBranchImages from "@/components/Customer/Branches/Branch/BranchDetails/Images/AddBranchImages"

export default {
  name: "BranchImages",
  components: {
    ItemCard,
    ShowImage,
    AddBranchImages
  },
  props: {
    branch: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>